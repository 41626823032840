<template>
    <el-form :model="formData" ref="formData" :rules="rules" class="card-tail w-full half-width" label-position="top" v-loading="$waiting.is('loading')">
        <div class="p-5">
            <header class="text-header-blue text-xl font-semibold mb-4">Bank</header>
            <div class="bg-gray-50 border border-gray-200 rounded-lg p-3 mt-4" v-if="!changeBankInfo">
                <div class="flex">
                    <el-form-item class="w-60" label="Clearingnummer">
                        <p class="text-sm font-thin">{{ formData.clearingNumber }}</p>
                    </el-form-item>
                    <el-form-item class="w-60" label="Bankkontonummer">
                        <p class="text-sm font-thin">{{ formData.bankAccountNumber }}</p>
                    </el-form-item>
                    <el-form-item class="relative w-60" label="Bank">
                        <p class="text-sm font-thin">{{ bankName }}</p>
                    </el-form-item>
                    <i @click="changeBankInfo = true" class="fa-solid fa-pencil absolute right-0 mr-11 cursor-pointer -mt-2" style="color: darkgray" />
                </div>
                <el-form-item class="w-60" label="PSD2 Medgivande">
                    <p class="text-sm font-thin">{{ formData.bankAuthorizationDate }}</p>
                </el-form-item>
            </div>
            <div v-if="changeBankInfo" class="border border-gray-200 rounded-lg p-3">
                <el-alert title="Notera: Vid byte av bankkontonummer måste samtycket avbrytas för det gamla kontonumret och öppna bankbehörighet tas bort" type="warning" :closable="false" />
                <el-alert v-if="errorText" class="mt-4" :title="errorText" type="error" :closable="false" />
                <div class="flex mt-4 space-x-2">
                    <el-form-item class="relative w-60" label="Clearingnummer" prop="clearingNumber">
                        <el-input v-model="formData.clearingNumber" />
                    </el-form-item>
                    <el-form-item class="relative w-60" label="Bankkontonummer" prop="bankAccountNumber">
                        <el-input v-model="formData.bankAccountNumber" />
                    </el-form-item>
                    <el-form-item class="w-60" label="Bank">
                        <el-select clearable class="w-60" v-model="formData.bankId" placeholder="--- Välj bank ---">
                            <el-option v-for="bank in formData.banks" :label="bank.name" :value="bank.value" :key="bank.value" />
                        </el-select>
                    </el-form-item>
                </div>
                <el-form-item class="w-60" label="PSD2 Medgivande">
                    <p class="text-sm font-thin">{{ formData.bankAuthorizationDate }}</p>
                </el-form-item>
                <div class="flex mt-10 space-x-2">
                    <el-form-item>
                        <el-button @click="changeBankInfo = false">Avbryt</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">Uppdatera bankinfo</el-button>
                    </el-form-item>
                    <el-form-item v-if="formData.bankAuthorizationConsent">
                        <el-button type="primary" @click="deleteOpenBankingAuthorization()">Inaktivera open banking</el-button>
                    </el-form-item>
                </div>
            </div>
            <div class="bg-gray-50 border border-gray-200 rounded-lg mt-4 p-3">
                <el-form-item>
                    <el-checkbox label="Autogiro medgivande" name="type" v-model="formData.permissionEnabled" />
                </el-form-item>
                <div class="flex">
                    <el-form-item class="w-60" label="Medgivande">
                        <p class="text-sm font-thin">{{ formData.statusName }}</p>
                    </el-form-item>
                    <el-form-item class="w-60" label="Betalarnummer" prop="name">
                        <p class="text-sm font-thin">{{ formData.betalarnummer }}</p>
                    </el-form-item>
                    <el-form-item class="w-60" label="Bankkontonummer" prop="name">
                        <p class="text-sm font-thin">{{ formData.bankAccountNumber2 }}</p>
                    </el-form-item>
                </div>
                <div class="flex mt-5 space-x-2">
                    <el-form-item>
                        <el-button type="primary" @click="updateClientPermission()">Spara</el-button>
                    </el-form-item>
                    <el-form-item v-if="formData.permissionEnabled">
                        <el-button type="danger" @click="createPermission()">Skapa autogiromedgivande</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="goBack()">Tillbaka</el-button>
                    </el-form-item>
                </div>
            </div>
        </div>
    </el-form>
</template>
<script>
import Api from "../clients.api";

export default {
    data() {
        return {
            errorText: "",
            formData: {},
            changeBankInfo: false,
            rules: {
                clearingNumber: [
                    { required: true, message: "Nödvändig", trigger: "blur" },
                    { pattern: /^([0-9]+)$/, message: "Kontrollera clearingnumret", trigger: "blur" },
                    { min: 1, max: 5, message: "Längden får inte vara längre än 6", trigger: "blur" },
                ],
                bankAccountNumber: [
                    { required: true, message: "Nödvändig", trigger: "blur" },
                    { pattern: /^([0-9- -.-,]+)$/, message: "Kontrollera bankkontonummer", trigger: "blur" },
                    { min: 1, max: 15, message: "Längden får inte vara längre än 15", trigger: "blur" },
                ],
                bankName: [{ required: true, message: "Nödvändig", trigger: "blur" }],
            },
        };
    },

    async created() {
        this.getBankInfo();
    },

    computed: {
        bankName() {
            if (this.formData.bankId == null) {
                return this.formData.bankName;
            } else {
                return this.formData.banks.find(b => b.value == this.formData.bankId).name;
            }
        },
    },

    methods: {
        async getBankInfo() {
            this.$waiting.start("loading");
            this.formData = await Api.getBank(this.$route.params.clientId);
            this.$waiting.end("loading");
        },

        async updateClientPermission() {
            this.$waiting.start("loading");
            await Api.updateClientPermission(this.$route.params.clientId, this.formData.permissionEnabled);
            this.$waiting.end("loading");
            this.$notify.success({ title: "Utfört" });
        },

        async deleteOpenBankingAuthorization() {
            this.$waiting.start("loading");
            this.formData = await Api.deleteAuthorization(this.$route.params.clientId);
            this.$notify.success({ title: "Utfört" });
            this.changeBankInfo = false;
            this.getBankInfo();
        },

        async onSubmit() {
            await new Promise(resolve => this.$refs.formData.validate(valid => valid && resolve()));
            if (this.formData.registrationNumber === "" || this.formData.registrationNumber === undefined) {
                this.errorText = "Kontrollera organisationsnumret";
                return;
            }
            this.$waiting.start("loading");
            await Api.updateClientBank({ clientId: this.$route.params.clientId, clientDetails: this.formData });
            this.changeBankInfo = false;
            this.getBankInfo();
            this.$notify.success({ title: "Utfört" });
        },

        async createPermission() {
            await new Promise(resolve => this.$refs.formData.validate(valid => valid && resolve()));
            if (this.formData.registrationNumber === "" || this.formData.registrationNumber === undefined) {
                this.errorText = "Kontrollera organisationsnumret";
                return;
            }
            this.$waiting.start("loading");
            const createPermissionDto = {
                permissionEnabled: this.formData.permissionEnabled,
                clearingNumber: this.formData.clearingNumber,
                bankAccountNumber: this.formData.bankAccountNumber,
            };
            const message = await Api.createPermission({ clientId: this.$route.params.clientId, permissionDto: createPermissionDto });
            this.getBankInfo();
            this.$notify.success({ title: message.data });
        },
        goBack() {
            this.$router.push({ path: "/clientList" });
        },
    },
};
</script>
<style scoped>
@media screen and (min-width: 1383px) {
    .half-width {
        width: 50%;
    }
}
</style>
