import { render, staticRenderFns } from "./ClientBank.vue?vue&type=template&id=50e59eac&scoped=true&"
import script from "./ClientBank.vue?vue&type=script&lang=js&"
export * from "./ClientBank.vue?vue&type=script&lang=js&"
import style0 from "./ClientBank.vue?vue&type=style&index=0&id=50e59eac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e59eac",
  null
  
)

export default component.exports